.welcome-ctn {
  background: url('../../assets/images/b2cbanner/thank-you-wallpaper-ipl.webp') !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  padding: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .welcome-card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Montserrat', sans-serif !important;

    .wel-item-ctn {
      margin-top: 4rem;
      width: 380px;
      min-width: 380px;
      max-height: unset;
      display: flex;
      padding: 0%;
      flex-direction: column;
      background-color: var(--ion-background-color);
      border-radius: 17px;
      overflow: hidden !important;

      .content_align_cen {
        text-align: center;
      }

      .welcome_logo {
        width: 173px;
        margin: 28px auto 0px;
      }

      .text1_align {
        font-size: 14px;
        color: #545454;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif !important;
      }

      .service_align {
        font-size: 14px;
        color: #545454;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif !important;
        display: flex;
        align-content: center;
        margin-bottom: 10px;
      }

      .text_align_WelBonus {
        font-size: 24px;
        font-weight: 600;
        color: #545454;
        padding: 10px 0;
        font-family: 'Montserrat', sans-serif !important;
      }

      .text_algin_2 {
        font-family: Bebas Neue !important;
        font-size: 80px;
        font-weight: 600;
        line-height: 80px;
        letter-spacing: 0.03em;
        text-align: center;
        color: #08ba62;
        display: block;
      }

      .cashback_align {
        font-family: Montserrat !important;
        font-size: 19px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        color: #08ba62;
      }

      .payment-icons-block {
        width: 90%;
        margin: 0 auto;

        img {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .icon_align {
        width: 23px;
        margin-right: 6px;
      }

      .claim-btn-wel {
        width: 228px;
        display: inline-block;
        background: var(--ion-color-primary);
        color: #000;
        text-decoration: none;
        padding: 6px;
        font-size: 18px;
        border-radius: 4px;
        font-weight: bold;
      }

      .claim-btn-fair {
        width: 150px;
        display: inline-block;
        background: var(--ion-color-primary);
        color: #000;
        text-decoration: none;
        padding: 6px;
        font-size: 18px;
        border-radius: 4px;
        font-weight: bold;
      }

      .icon_bank_align {
        width: 34px;
      }

      .icon_paytm_align {
        width: 55px;
      }

      .icon_upi_align {
        width: 59px;
      }

      .icon_phonepe_align {
        width: 36px;
      }

      .icon_googleplay_align {
        width: 48px;
      }

      .mrl25 {
        margin-left: 25px;
      }

      .payment-method {
        padding-bottom: 40px;
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .welcome-ctn {
    .welcome-card {
      position: initial;
      transform: none;
      margin: 0 20px;

      .wel-item-ctn {
        width: 100%;
        min-width: 100%;
        margin-top: 1rem;

        .welcome_logo {
          width: 150px;
          margin: 15px auto 0px;
        }

        .text_align_WelBonus {
          font-size: 20px;
          padding: 0px 0;
        }

        .mrl25 {
          margin-left: 10px;
        }

        .text_algin_2 {
          font-size: 60px;
        }

        .icon_bank_align {
          width: 26px;
        }

        .payment-icons-block img {
          margin-right: 15px;
        }

        .icon_upi_align {
          width: 46px;
        }

        .icon_paytm_align {
          width: 46px;
        }

        .icon_phonepe_align {
          width: 32px;
        }

        .icon_googleplay_align {
          width: 42px;
        }

        .service_align {
          font-size: 13px;
        }

        .cashback_align {
          font-size: 16px;
          line-height: 0;
        }
      }
    }
  }
}

.faircric-signup {
  background: url('../../assets/images/faricimage/signup-background.png')
    no-repeat;
  background-size: 100% 100% !important;
  padding: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  @media screen and (max-width: 720px) {
    background: url('../../assets/images/faricimage/signup-mob-background.png')
      no-repeat;
  }

  .title-logo.title-row {
    justify-content: start;
    position: absolute;
    padding-top: 0;
    left: 30px;
    top: 20px;

    .logo {
      height: 30px;
    }

    @media screen and (max-width: 720px) {
      position: unset;

      .logo {
        height: 40px;
      }

      .card-bg {
        border-radius: 24px !important;
      }
    }
  }

  &.sign-up-ctn .card-col .card-bg .card-section {
    width: unset;
    padding: 40px 48px;

    @media screen and (max-width: 720px) {
      padding: 16px 38px 20px 38px;
    }

    .card {
      padding-top: 0;

      .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: var(--ion-color-primary);
        margin-top: 14px;
      }

      .page-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: #ffffff;
        text-align: left;
        margin-bottom: 12px;
      }

      .sub-title {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #a09d9d;
        padding-top: 16px;
      }

      .input-col {
        padding: 0;
      }

      .input-row {
        .label {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          margin-bottom: 10px;

          &.link {
            font-weight: unset;
            font-size: 11px;
          }
        }

        .MuiFormControl-root {
          margin-left: 0;

          .MuiInputBase-root {
            border-radius: 6px;
          }

          .MuiOutlinedInput-input {
            border-radius: inherit;
            background: #242424;
            border: 1px solid #545454;
            color: #fff;
          }
        }
      }

      .react-tel-input {
        margin-left: 0;
        border-radius: 6px !important;
        background: #242424;
        border: 1px solid #545454;
        color: #fff;

        .form-control {
          border-radius: inherit;
          background: #242424;
          border: 1px solid #545454;
          color: #fff !important;
        }

        .flag-dropdown {
          border-radius: inherit;
          background: #242424;
          border: 1px solid #545454;
          color: #fff;
          border-radius: 6px 0 0 6px;
        }
      }

      .label {
        margin-left: 0;
      }

      .btn-section {
        justify-content: start;
      }

      .msg-row {
        width: 210px;
      }
    }
  }
}

.MuiDialog-root.sign-up-modal {
  height: fit-content;
  margin: auto;
  padding: 0 10px;
  .MuiDialog-container {
    height: unset;
    outline: 0;
  }
  .MuiDialog-paper .MuiIconButton-root {
    position: unset;
  }

  .MuiPaper-root {
    border-radius: 24px;
    background-color: var(--signup-card-background-color);
    width: unset;

    .sign-up-ctn.faircric-signup {
      width: unset;
      height: unset;
      position: unset;
      background: var(--signup-card-background-color);
      overflow-y: hidden;

      .card-col {
        background-color: var(--signup-card-background-color);
      }

      .title-logo.title-row {
        display: none;
      }

      .MuiDialogContent-root {
        padding: 0;
      }

      .card-bg {
        margin-top: 0;
      }
    }

    .MuiDialogContent-root {
      padding: 0 !important;
    }
  }

  .modal-title {
    display: none;
  }
}
// .whatsapp-btn{
//   padding: 5px;
// }
.welcome-whatsApp{
 position: fixed;
 bottom: 0vh;
  height: 45px;
  margin-bottom: 10px;
}
.welcome-telegram{
  height: 45px;
  position: fixed;
  bottom: 0vh;
  margin-left: 35px;
  margin-bottom: 10px;
}
.whatsapp-gif{
  height: 243%;
  width: 42%;
  margin-top: -25px;
  @media screen and (max-width: 720px) {
    width: 60%;
  }
}
.whatsapp-g{
  height: 30px;
}
.watsapp-display-gif{
  height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.whatsapp-gif {
  height: 80px;
  width: 210px;
  text-align: center;
  margin-top: -10px;
}
.whatsapp-promotion-link{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: #000000;
  border: 1px#ffd402 solid;
  border-radius: 20px;

}
.text-wtsp-promotion{
  margin-top: 5px;
  color: white;
  display: flex;
}
.whatsapp-color{
  color: #08ba62;
}